// Logout

import { toast } from "react-toastify";
import Adminapi from "../../services/AdminApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const LogOut = createAsyncThunk("LogOut", async () => {
  console.log("first");
  const { data } = await Adminapi.patch("admin/logout");

  if (data?.status==200) {
    toast.success(data?.message);
    window.location.href = "/";
    sessionStorage.clear()
  } else {
    toast.error(data?.err);
  }
});

//   change password

export const Changepassword = createAsyncThunk(
  "Changepassword",
  async (details) => {
    const data = await Adminapi.patch("admin/change-password", details);
    console.log(data);
    return data;
  }
);

// Forgot Password

export const ForgotPass = createAsyncThunk(
  "ForgotPassword",
  async (details) => {
    const data = await Adminapi.post("admin/forgot-password", details);
    return data;
  }
);

//  OtpVerify

export const OtpVerify = createAsyncThunk("OtpVerify", async (details) => {
  const data = await Adminapi.post("/admin/forgot-verify", details);
  return data;
});

// resetpassword

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const data = await Adminapi.post("/admin/set-password", details);
    return data;
  }
);

//   user listing

export const userListing = createAsyncThunk("userListing", async (details) => {
  // Extract details for easy reference
  const { page, limit, search, type } = details;

  // Initialize the base URL
  let url = `/admin/users`;

  // Construct query parameters
  const params = new URLSearchParams();

  if (search) {
    params.append('search', search);
  }
  if (page) {
    params.append('pagination', page);
  }
  if (limit) {
    params.append('limit', limit);
  }
  if (type) {
    params.append('account_status', type);
  }

  // Append query parameters to the URL
  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  // Make the API request
  const { data } = await Adminapi.get(url);

  return data;
});



//  change profile status and suspend Account

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (details) => {
    console.log(details,"details")
    const { data } = await Adminapi.patch(`admin/users/manage/${details?.id}`,details?.status);

    return data;
  }
);

// Get user profile

export const getUserProfile = createAsyncThunk(
  "getUserProfile",
  async (details) => {
    
    const {data} = await Adminapi.get(`admin/users/${details.userId}`);
    return data;
  }
);


// Delete Account

export const DelecteAccount = createAsyncThunk(
  "DelecteAccount",
  async (details) => {

    const data = await Adminapi.delete(`admin/users/${details}`);
    return data;
  }
);

// user list download

export const userListingDownload = createAsyncThunk(
  "userListingDownload",
  async (details) => {
    const { data } = await Adminapi.get(`/userManagement`);

    return data;
  }
);

// dashboard

export const dashboard = createAsyncThunk(
  "dashboard",
  async (details) => {
    const { data } = await Adminapi.get(`admin/dashboard`);

    return data;
  }
);


