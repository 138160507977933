import React from "react";

export default function TermsofUse() {
  return (
    <div class="container">
      <div class="privacy_text">
        <h1 class="primary-font">Terms of Use</h1>
        <p>
          Welcome to She Tracks operated by She Tracks L.L.C (“She Tracks”,
          “us”, “our”, and “we”). She Tracks is an application (“App”) that
          integrates menstrual cycle tracking, fitness progress monitoring, food
          journal management, and intermittent fasting schedules in one place.
          She Tracks provides the App and services provided through the App
          (“Services”)(the Services and App are collectively referred to as
          “Platform”)
        </p>
        <p>
          These Terms of Use (“Terms”) set forth the legally binding terms for
          your (“User”, “you”, and “your”) use of the Services. By accessing or
          using the Services, you are accepting these Terms and you represent
          and warrant that you have the right, authority, and capacity to enter
          into these Terms. If you do not agree with all of the provisions of
          these Terms, do not access and/or use the Services. You may not access
          or use the Services or accept the Terms if you are not at least 18
          years old.
        </p>
        <h2>Privacy Policy</h2>
        <p>
          The Company respects the privacy of its users. Please refer to the
          Company’s Privacy Policy (https://companyurl/privacy) which explains
          how we collect, use, and disclose information that pertains to your
          privacy. When you access or use the Service, you signify your
          agreement to this Privacy Policy.
        </p>
        <h4>Platform Features and Content</h4>
        <p>
          Our Platform may provide informational and educational content. All
          content on the Platform is for informational and educational purposes
          only, is general in nature, and under no circumstance does the content
          on our Platform undertake to give advice for your particular
          situation. The content on our Platform is not meant to be relied upon
          by Platform visitors as medical advice or in reliance on making any
          decisions. Please consult a physician or doctor for medical advice.
          You acknowledge that you are participating voluntarily in using our
          Platform or in any of our e-mails, programs, services, and/or
          products, and you alone are solely and personally responsible for your
          results. You acknowledge that you take full responsibility for your
          health, life and well-being and all decisions now or in the future. In
          the event that you use the information provided through our Platform,
          e-mails, programs, services, and/or products, we assume no
          responsibility.
        </p>
        <p>
          The Platform provides tools for users to track and monitor personal
          health data, including menstrual cycle information, dietary habits,
          and weight fluctuations. Users acknowledge that self-monitoring such
          health information involves personal responsibility. She Tracks is not
          a licensed medical care provider and represents that it has no
          expertise in diagnosing, examining, or treating medical conditions of
          any kind, or in determining the effect of any specific exercise on a
          medical condition. The information and features provided on the
          Platform is not intended to replace professional medical advice,
          diagnose, treat, or manage any health condition, nor serve as a method
          of birth control or contraception. You should consult with a licensed
          physician or other qualified healthcare provider before making any
          health-related decisions or taking any actions that could affect your
          health or that of your family. Never ignore or delay seeking
          professional medical advice because of information accessed through
          the Platform. For any questions or concerns about your health, or if
          you experience changes in your health condition, always consult with
          your healthcare provider. If you are experiencing a medical emergency,
          call emergency services or go to the nearest emergency room
          immediately.
        </p>
        <p>
          There is no guarantee that you will experience specific results from
          any of our Services and you accept the risk that results differ by
          individual. Each individual’s health, fitness, and nutrition success
          depends on his or her background, dedication, desire, and motivation.
          Your results may vary, and will be based on many variables, including
          but not limited to, your individual capacity, life experience, unique
          health and genetic profile, starting point, expertise, and level of
          commitment.{" "}
        </p>
        <h4>Explicit Consent for Sensitive Data</h4>
        <p>
          By entering information related to menstrual cycles, weight,
          prescriptions, mood, and other personal data into the Platform, you
          explicitly consent to She Tracks' collection, processing, and storage
          of this sensitive information. She Tracks collects and processes this
          data to provide personalized insights, tracking, and analysis for the
          user’s benefit. You acknowledge that by providing this information,
          you grant She Tracks permission to use it as outlined in the Privacy
          Policy and these Terms of Use.
        </p>
        <h4>Accounts </h4>
        <p>
          Account Creation. In order to use certain features of the Services,
          you must register for an account with us (“your Account”) and provide
          certain information about yourself as prompted by the registration
          form. You represent and warrant that: (a) all required registration
          information you submit is truthful and accurate; (b) you will maintain
          the accuracy of such information. You may delete your Account at any
          time, for any reason, by following the instructions on the Platform.
          We may suspend or terminate your Account in accordance with the Terms
          and Termination.{" "}
        </p>
        <p>
          Account Responsibilities. You are responsible for maintaining the
          confidentiality of your Account login information and are fully
          responsible for all activities that occur under your Account. You
          agree to immediately notify us of any unauthorized use, or suspected
          unauthorized use, of your Account or any other breach of security. We
          cannot and will not be liable for any loss or damage arising from your
          failure to comply with the above requirements.
        </p>
        <h4>Payment Terms</h4>
        <p>
          Pricing and Subscriptions. Certain aspects of the Services may be
          provided for a fee, subscription or other charge. If you purchase a
          subscription from She Tracks, this subscription will automatically
          renew at the end of each billing cycle, at which point you will
          automatically be charged on your anniversary date for the next billing
          cycle. If you upgrade your subscription you will be prorated for the
          remainder of the billing cycle and you will be billed the full price
          of the upgraded subscription the following month. One-time purchases
          will be charged at the time of the transaction. She Tracks may add new
          services for additional fees and charges, and add or amend fees and
          charges for existing services, at any time in its sole discretion. Any
          change to our pricing or payment terms shall become effective in the
          billing cycle following notice of such change to you as provided in
          these Terms.
        </p>
        <p>
          Payment Information; Taxes. All information that you provide in
          connection with a purchase or transaction must be accurate, complete,
          and current. You agree to pay all charges incurred by users of your
          credit card, debit card, or other payment method used in connection
          with a purchase or transaction at the prices in effect when such
          charges are incurred. You will pay any applicable taxes, if any,
          relating to any such purchases or transactions.
        </p>
        <p>
          Trial Period or Discount Rates. She Tracks may offer free trial
          subscriptions or discounted introductory subscriptions to the Platform
          at our sole discretion, allowing new (first-time) users to explore the
          benefits of our subscription plans. Each trial or introductory offer
          is limited to one per household, is available only to new users, and
          cannot be combined with any other offer. Following the end of your
          trial or introductory offer period, if you do not opt to subscribe to
          the ad-free version, your account will automatically transition to the
          free version with ads.
        </p>
        <p>
          Cancellation. Users may cancel their Account or any paid Service at
          any time by following the instructions in your Account. If you cancel
          Services that were a part of any automatic recurring subscription,
          those Services will terminate at the end of your current billing
          cycle. If you wish to cancel any Service before you are automatically
          charged for the next billing cycle, we recommend you take the steps
          necessary to cancel such Services no later than five (5) days before
          the end of the current billing cycle to allow enough time for the
          cancellation to process.
        </p>
        <p>
          Chargebacks. If you have a dispute concerning any payment transaction,
          please contact us at shetracksapp@gmail.com. If, at any time, you
          contact your bank or credit card company to reject the charge of any
          payable fees (“Chargeback”), this act will be considered a breach of
          your payment obligations, and we reserve the right to automatically
          terminate your use of the Services. We reserve the right to dispute
          any Chargeback and take all reasonable action to authorize the
          transaction. In the event of a Chargeback, your User Account may be
          terminated and any files, data, or content contained in your Account
          may be subject to cancellation. We will not be liable for any loss of
          files, data or content as a result of a Chargeback. Without limiting
          other available remedies, you must pay She Tracks upon demand for
          amounts owed under these Terms, plus interest on the outstanding
          amount at the lesser of one percent (1%) per month or the maximum
          interest allowed by applicable law, plus attorneys’ fees and other
          costs of collection to the extent permitted by applicable law.
        </p>
        <h4>Advertisements</h4>
        <p>
          Free and Paid Versions. She Tracks offers both a free version of the
          Platform that includes advertisements and a paid ad-free version. By
          using the free version of the Platform, you acknowledge and agree to
          the display of advertisements, which may be tailored to your interests
          based on the data we or our advertising partners collect from you. She
          Tracks does not control, endorse, or guarantee the accuracy, quality,
          or reliability of any advertisements or promotional materials that
          appear within the Platform. Any interactions you have with advertisers
          or third-party services are solely between you and the advertiser, and
          She Tracks assumes no responsibility for the content, products, or
          services advertised, nor for any actions you take in response to
          advertisements within the Platform.
        </p>
        <p>
          If you choose to upgrade to the paid ad-free version, you will no
          longer see advertisements while using the Platform. The absence of
          advertisements in the paid version does not imply endorsement or
          partnership with any specific products or services, nor does it imply
          any additional liability or responsibility for the functionality,
          performance, or content of the Platform.
        </p>
        <p>
          Ad Content and Third-Party Services. She Tracks may, at its
          discretion, display third-party advertisements, sponsored content, or
          offers within the free version of the Platform. She Tracks does not
          endorse or verify the legitimacy of any advertisements or third-party
          links, nor does it provide any warranties or representations related
          to any products, services, or content featured in the advertisements.
          All advertisements are provided by third-party partners, and She
          Tracks is not liable for any direct or indirect damages, losses, or
          issues resulting from your interactions with such third-party
          advertisers or any claims you may have related to third-party products
          or services.
        </p>
        <p>
          User Discretion and Assumption of Risk. By using the Platform and
          accessing third-party advertisements or offers, you acknowledge and
          accept full responsibility for any risks associated with engaging with
          advertisers, including, but not limited to, purchasing goods or
          services, clicking on third-party links, or entering into any
          transaction with third parties. It is your responsibility to carefully
          evaluate the advertisements and offers displayed within the Platform
          and to perform appropriate due diligence before proceeding with any
          transactions.
        </p>
        <h4>Third-Party Device Integration and Data Accuracy</h4>
        <p>
          She Tracks offers the ability to sync and integrate data from various
          third-party devices and services, including but not limited to Apple
          Watch, Fitbit, and Oura Ring, to enhance your experience on the
          Platform. By enabling these integrations, you acknowledge and agree to
          the following:
        </p>
        <ul>
          <li>
            Data Origin: Any workout, calorie burned, or other fitness tracking
            data displayed within the Platform originates from third-party
            devices and services. She Tracks relies on these external sources to
            provide this information and has no control over the accuracy,
            reliability, or completeness of the data generated by these devices.
          </li>
          <li>
            Accuracy Disclaimer: While She Tracks aims to provide accurate
            information based on the data received from third-party devices, the
            Platform does not warrant or guarantee the precision, completeness,
            or usefulness of the data imported from these sources. Data
            inconsistencies, inaccuracies, or delays may occur, and She Tracks
            cannot be held liable for any errors or discrepancies in the data
            provided by third-party services.
          </li>
          <li>
            User Responsibility: It is your responsibility to ensure that your
            third-party devices are set up correctly and functioning as
            intended. She Tracks encourages you to consult the user manuals and
            support resources of your third-party devices to understand how data
            is collected, measured, and reported.
          </li>
          <li>
            No Substitute for Professional Advice: The data imported from
            third-party devices and displayed on the Platform is for
            informational purposes only and should not be considered a
            substitute for professional health, fitness, or medical advice.
            Always consult with a qualified healthcare or fitness professional
            regarding your exercise routines, calorie tracking, or any
            health-related concerns.
          </li>
          <li>
            Third-Party Terms and Privacy Policies: By connecting third-party
            devices to the Platform, you agree to the terms of use and privacy
            policies of those respective devices and services. She Tracks is not
            responsible for any data collection, processing, or privacy
            practices of these third-party providers. We encourage you to review
            the privacy policies of Apple, Fitbit, Oura Ring, and any other
            connected services to understand how your data is managed outside of
            the Platform.
          </li>
        </ul>
        <p>
          By using the Platform with connected third-party devices, you
          acknowledge and accept these terms and release She Tracks from any
          liability associated with the accuracy or reliability of the data
          imported from these external sources.
        </p>
        <p>
          Location Information; Consent to Use of Data and Mobile Communications
        </p>
        <p>
          She Tracks may implement a location feature where the Services will
          automatically collect your geolocation information if you provide your
          consent. She Tracks may also use location information to provide
          information and advertising to Users. IF YOU WANT TO STOP THE
          AUTOMATIC COLLECTION OF YOUR LOCATION INFORMATION, YOU MAY DO SO BY
          REMOVING LOCATION PERMISSIONS FROM YOUR BROWSER AND/OR USING THE
          PRIVACY SETTINGS ON YOUR DEVICE AND/OR BY UNINSTALLING THE APP.{" "}
        </p>
        <p>
          You also consent to She Tracks communicating with you about the
          Service or in connection with the features, functions and activities
          contained on the Services by SMS, text message, email, social media,
          or other electronic means. Your carrier's normal, messaging, data and
          other rates and fees will apply to these communications. You may
          opt-out of receiving SMS or text messages at any time.{" "}
        </p>
        <h4>Mobile Apps</h4>
        <p>
          We may provide a mobile application (“App”) as another platform for
          accessing the Services. Some of the functionality of the Services may
          only be available on the App and such functionality may vary between
          types of users. To use the App you must have a mobile device that is
          compatible with the App. We do not warrant that the App will be
          compatible with your mobile device.{" "}
        </p>
        <p>
          You may not: a) modify, disassemble, decompile or reverse engineer the
          App; b) rent, lease, loan, resell, sublicense, distribute or otherwise
          transfer the App to any third-party; c) make any copies of the App; d)
          remove, circumvent, disable, damage or otherwise interfere with
          security-related features of the App, features that prevent or
          restrict use or copying of any content accessible through the App, or
          features that enforce limitations on use of the App; or e) delete the
          copyright and other proprietary rights notices on the App.{" "}
        </p>
        <p>
          You acknowledge that we may from time to time issue upgraded versions
          of the App, and may upgrade automatically to your mobile device the
          version of the App that you are using. You consent to such automatic
          upgrading and agree that these Terms of Use will apply to all such
          upgrades. We may elect to provide you with support or modifications
          for the App (“Support”), in our sole discretion, and we may change,
          reduce or terminate such Support at any time without notice to you. We
          reserve the right to charge fees for Support. Any use of third-party
          software provided in connection with the Services will be governed by
          such third parties’ licenses and not by these Terms of Use.
        </p>
        <p>
          App Stores. You acknowledge and agree that the availability of the
          Mobile Apps is dependent on the third party websites from which you
          download the Mobile Apps, e.g., the App Store from Apple or the
          Android app market from Google (each an “App Store”). You acknowledge
          that these Terms are between you and She Tracks and not with an App
          Store. Each App Store may have its own terms and conditions to which
          you must agree before downloading Mobile Apps from it. You agree to
          comply with, and your license to use the Mobile Apps is conditioned
          upon your compliance with, such App Store terms and conditions. To the
          extent such other terms and conditions from such App Store are less
          restrictive than, or otherwise conflict with, the terms and conditions
          of these Terms, the more restrictive or conflicting terms and
          conditions in these Terms apply.
        </p>
        <h4>Rights and Licenses</h4>
        <p>
          License to Use Platform. We grant you a non-transferable,
          non-exclusive, right to access and use the Services for your personal
          use.
        </p>
        <p>
          Certain Restrictions. The rights granted to you in these Terms are
          subject to the following restrictions: (a) you will not license, sell,
          rent, lease, transfer, assign, distribute, host, or otherwise
          commercially exploit the Services; (b) you will not modify, make
          derivative works of, disassemble, reverse compile or reverse engineer
          any part of the Services; (c) you will not access the Services in
          order to build a similar or competitive service; and (d) except as
          expressly stated in these terms, no part of the Services may be
          copied, reproduced, distributed, republished, downloaded, displayed,
          posted or transmitted in any form or by any means. Any future release,
          update, or other addition to functionality of the Services will be
          subject to these Terms. All copyright and other proprietary notices on
          any Services content must be retained on all copies.{" "}
        </p>
        <p>
          Modification. We reserve the right, at any time, to modify, suspend,
          or discontinue the Services with or without notice. You agree that we
          will not be liable to you or to any third party for any modification,
          suspension, or discontinuance of the Services, except and if otherwise
          expressly set forth in these Terms.
        </p>
        <p>
          Ownership of the Services. Excluding your User Content (defined
          below), you acknowledge that all the intellectual property rights,
          including copyrights, patents, trademarks, and trade secrets, in the
          Services, including the Platform, are owned by us or our licensors.
          The provision of the Services does not transfer to you or any third
          party any rights, title or interest in or to the intellectual property
          rights. We reserve all rights not granted in these Terms.
        </p>
        <h4>User Content</h4>
        <p>
          User Content. “User Content” means any and all information and content
          that a user submits to or posts on the Platform. You will own your
          User Content, with the understanding that you agree that we may use
          and reproduce the User Content you make available on our Platform. You
          are solely responsible for the User Content that you post, upload,
          link to, or otherwise make available via the Service. We reserve the
          right to remove any User Content from the Service at our discretion.
        </p>
        <p>
          The following rules pertain to User Content. By transmitting and
          submitting any User Content while using the Service, you agree as
          follows:
        </p>
        <ul>
          <li>
            You are solely responsible for your account and the activity that
            occurs while signed in to or while using your account;
          </li>
          <li>
            You will not submit content that is copyrighted or subject to
            third-party proprietary rights, including privacy, publicity, trade
            secret, etc., unless you are the owner of such rights or have the
            appropriate permission from their rightful owner to specifically
            submit such content;
          </li>
          <li>You will abide by our Acceptable Use Policy below; and</li>
          <li>
            You affirm we have the right to determine whether any of your User
            Content submissions are appropriate and comply with these Terms of
            Use, remove any and/or all of your submissions, and terminate your
            account with or without prior notice.
          </li>
        </ul>
        <p>
          You understand and agree that any liability, loss or damage that
          occurs as a result of the use of any User Content that you make
          available or access through your use of the Service is solely your
          responsibility. We are not responsible for any public display or
          misuse of your User Content. We do not, and cannot, pre-screen or
          monitor all User Content. However, at our discretion, we, or
          technology we employ, may monitor and/or record your interactions with
          the Service.
        </p>
        <p>
          License. You grant, and you represent and warrant that you have the
          right to grant, to us an irrevocable, nonexclusive, royalty-free and
          fully paid, worldwide license to reproduce, distribute, publicly
          display and perform, prepare derivative works of, incorporate into
          other works, and otherwise use your User Content, and to grant
          sublicenses of the foregoing, solely for the purposes of including
          your User Content in the Platform and Services. You agree to
          irrevocably waive (and cause to be waived) any claims and assertions
          of moral rights or attribution with respect to your User Content.
        </p>
        <h4>Acceptable Use Policy</h4>
        <p>
          Your permission to use the Services is conditioned upon the following
          restrictions: You agree that you will not under any circumstances:
        </p>
        <ul>
          <li>
            post any information that is abusive, threatening, obscene,
            defamatory, libelous, or racially, sexually, religiously, or
            otherwise objectionable and offensive;
          </li>
          <li>
            use the service for any unlawful purpose or for the promotion of
            illegal activities;
          </li>
          <li>attempt to, or harass, abuse or harm another person or group;</li>
          <li>use another user’s account without permission;</li>
          <li>
            provide false or inaccurate information or impersonate another
            person when registering an account;
          </li>
          <li>
            interfere or attempt to interfere with the proper functioning of the
            Service;
          </li>
          <li>
            make any automated use of the system, or take any action that we
            deem to impose or to potentially impose an unreasonable or
            disproportionately large load on our servers or network
            infrastructur
          </li>
          <li>
            use the Platform or any of its contents to advertise or solicit, for
            any commercial purpose or to compete, directly or indirectly, with
            our Service;
          </li>
          <li>
            bypass any robot exclusion headers or other measures we take to
            restrict access to the Service or use any software, technology, or
            device to scrape, spider, or crawl the Service or harvest or
            manipulate data; or
          </li>
          <li>
            publish or link to malicious content intended to damage or disrupt
            another user’s browser or computer.
          </li>
        </ul>
        <p>
          User Guidelines. She Tracks may create additional user guidelines
          (“User Guidelines'') that share expectations of how Users should
          interact with one another in order to create an enjoyable experience
          on the Platform. By accepting these Terms, you agree that She Tracks,
          in its sole discretion, may enforce such User Guidelines and suspend
          or terminate your Account for violations of such User Guidelines.
        </p>
        <p>
          Reporting Abuse. If you see content that does not align with our
          guidelines or Terms, please let us know. We will consider all reports
          and take each report seriously. However, due to the diversity of our
          community, it is possible that content disagreeable to you might not
          meet the criteria to be removed. We do our best to moderate the
          community and ultimately, the decision of whether to keep or remove
          content from our Platform is ours to make. Sometimes that just means
          giving someone a warning; other times it means revoking certain
          privileges or accounts entirely.
        </p>
        <p>
          Feedback. If you provide us any feedback or suggestions regarding the
          Services (“Feedback”), you assign to us all rights in the Feedback and
          agree that we will have the right to use the Feedback and related
          information in any manner we deem appropriate. We will treat any
          Feedback you provide to us as non-confidential and non-proprietary.
          You agree that you will not submit to us any information or ideas that
          you consider to be confidential or proprietary.
        </p>
        <h4>Maintenance, Updates, and Discontinuance</h4>
        <p>
          From time to time, She Tracks may update, modify, expand, or improve
          the App to enhance its functionality and performance. We may also, at
          any time and without prior notice, choose to disable certain features,
          suspend access, or cease operation of part or all of the App. Your use
          of the App does not guarantee the ongoing availability of any
          particular features or functionality.
        </p>
        <p>
          Any modifications or discontinuation of the App or specific features
          will be determined at our sole discretion and without any obligation
          or liability to you. She Tracks may, at any time, suspend, limit, or
          discontinue access to content, features, or online servers associated
          with the App for reasons including maintenance, upgrades, or service
          changes, without notice or liability.
        </p>
        <h4>Indemnity</h4>
        <p>
          You agree to indemnify and hold us (and our officers, employees, and
          agents) harmless, including costs and attorneys’ fees, from any claim
          or demand made by any third party due to or arising out of (a) your
          use of the Platform and the Services, (b) your User Content, (c) your
          violation of these Terms; or (d) your violation of applicable laws or
          regulations. We reserve the right, at your expense, to assume the
          exclusive defense and control of any matter for which you are required
          to indemnify us and you agree to cooperate with our defense of these
          claims. You agree not to settle any matter without our prior written
          consent. We will use reasonable efforts to notify you of any such
          claim, action, or proceeding upon becoming aware of it.
        </p>
        <h4>Links to Other Platforms and/or Materials</h4>
        <p>
          Third Party Sites, and Third-Party Content. As part of the Service, we
          may provide you with convenient links to third party website(s)
          (“Third-Party Sites”) as well as content or items belonging to or
          originating from third parties such as applications, software, or
          content (collectively the “Third-Party Content”). Users may also
          include links to their website or other Third-Party Sites on their
          listings. These links are provided as a courtesy to Users. We have no
          control over Third-Party Sites and Third-Party Content or the
          promotions, materials, information, goods or services available on
          these Third-Party Sites or Third-Party Content. If you decide to leave
          the Platform and access the Third-Party Sites or to use or install any
          Third-Party Content, you do so at your own risk and you should be
          aware that our terms and policies no longer govern. You should review
          the applicable terms and policies, including privacy and data
          gathering practices, of any site to which you navigate from the
          Platform or relating to any applications you use or install from the
          site.
        </p>
        <p>
          Links to Our Platform. You are permitted to link to our Platform for
          non-commercial purposes, provided that you do so in a way that is fair
          and legal and does not damage our reputation. You may not link to our
          Platform in such a way as to suggest any form of association,
          approval, or endorsement on our part without our express written
          consent. You may not deep-link to any page of this site for any
          purpose whatsoever unless the link is expressly authorized in writing
          by us. We reserve the right to withdraw permission for any link.
        </p>
        <p>
          Release. You release and forever discharge us (and our officers,
          employees, agents, successors, and assigns) from, and waive and
          relinquish, each and every past, present and future dispute, claim,
          controversy, demand, right, obligation, liability, action and cause of
          action of every kind and nature (including personal injury, death, and
          property damage), that has arisen or arises directly or indirectly out
          of, or relates directly or indirectly to, any interactions with, or
          act or omission of, other Service users or Third-Party Sites, and
          Third-Party Content. YOU KNOWINGLY AND VOLUNTARILY WAIVE ANY
          PROTECTION THAT YOU MIGHT HAVE BY VIRTUE OF ANY LAW OR STATUTE
          PROVIDING THAT A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
          RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN ITS FAVOR AT THE
          TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY IT, WOULD HAVE
          MATERIALLY AFFECTED ITS SETTLEMENT WITH THE RELEASED PARTY.
        </p>
        <h4>Disclaimers</h4>
        <p>
          THE SERVICES, INCLUDING THE SITE, ARE PROVIDED “AS-IS” AND “AS
          AVAILABLE” AND WE EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF
          ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO
          WARRANTY THAT THE SERVICES: (a) WILL MEET YOUR REQUIREMENTS; (b) WILL
          BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS;
          (c) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE,
          COMPLETE, LEGAL, OR SAFE OR (d) THAT THE SERVICES WILL BE TO YOUR
          SATISFACTION.
        </p>
        <p>
          She Tracks makes no representation that materials on this Platform are
          appropriate or available for use in locations outside the United
          States. Access to this site from countries or territories where such
          access is illegal is prohibited. Those who choose to access this
          Platform outside the United States do so on their own initiative and
          are responsible for compliance with local laws.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES,
          SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
        </p>
        <h4>Limitation on Liability</h4>
        <p>
          IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
          PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL
          OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR
          USE OF, OR INABILITY TO USE, THE SERVICES, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE
          SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
          RESULTING THEREFROM.
        </p>
        <p>
          IN NO EVENT WILL SHE TRACKS’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
          LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING,
          BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED THE AMOUNTS
          YOU’VE PAID SHE TRACKS IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE
          OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.{" "}
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </p>
        <h4>Term and Termination. </h4>
        <p>
          Subject to this Section, these Terms will remain in full force and
          effect while you use the Services. We may (a) suspend your rights to
          use the Platform and/or Services (including your Account) or (b)
          terminate these Terms, at any time for any reason at our sole
          discretion, including for any use of the Services in violation of
          these Terms. Upon termination of these Terms, your Account and right
          to access and use the Services will terminate immediately. You
          understand that any termination of your Account involves deletion of
          your User Content from our live databases. We will not have any
          liability whatsoever to you for any termination of these Terms,
          including for termination of your Account or deletion of your User
          Content. Upon termination of these Terms, all of the provisions will
          terminate except those that by their nature should survive.
        </p>
        <h4>Copyright Policy</h4>
        <p>
          We respect the intellectual property of others and ask that users of
          our Platform and Services do the same. In connection with our Platform
          and Services and in accordance with the Digital Millennium Copyright
          Act’s (“DMCA”), we have adopted and implemented a policy respecting
          copyright laws that provide for the removal of any infringing
          materials and for the termination, in appropriate circumstances, of
          users of our online Services who are repeat infringers of intellectual
          property rights, including copyrights. If you believe that one of our
          users is, through the use of our Services, unlawfully infringing the
          copyright(s) in a work, and wish to have the allegedly infringing
          material removed, the following information in the form of a written
          notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our
          designated Copyright Agent:
        </p>
        <ul>
          <li>your physical or electronic signature;</li>
          <li>
            your physical or electronic signature; identification of the
            copyrighted work(s) that you claim to have been infringed;
          </li>
          <li>
            identification of the material on our Services that you claim is
            infringing and that you request us to remove;
          </li>
          <li>sufficient information to permit us to locate such material;</li>
          <li>your address, telephone number, and e-mail address;</li>
          <li>
            a statement that you have a good faith belief that use of the
            objectionable material is not authorized by the copyright owner, its
            agent, or under the law; and
          </li>
          <li>
            a statement that the information in the notification is accurate,
            and under penalty of perjury, that you are either the owner of the
            copyright that has allegedly been infringed or that you are
            authorized to act on behalf of the copyright owner.
          </li>
        </ul>
        <p>
          Please note that, pursuant to 17 U.S.C. § 512(f), any
          misrepresentation of material fact (falsities) in a written
          notification automatically subjects the complaining party to liability
          for any damages, costs and attorney’s fees incurred by us in
          connection with the written notification and allegation of copyright
          infringement.
        </p>
        <p className="m-0">Our designated Copyright Agent is:</p>
        <p className="m-0"> She Tracks</p>
        <p className="m-0">Attn: Copyright Compliance Department</p>
        <p className="m-0"> Address:</p>
        <p>Email:</p>
        <h4>Counter Notice Procedures </h4>
        <p>
          If you receive a notification from She Tracks that material made
          available by you on or through the Platform has been the subject of a
          Notification of Claimed Infringement, then you will have the right to
          provide She Tracks with what is called a "Counter Notification." To be
          effective, a Counter Notification must be in writing, provided to
          Company's Designated Agent through one of the methods identified
          above, and include substantially the following information:
        </p>
        <ul>
          <li>A physical or electronic signature of the subscriber;</li>
          <li>
            Identification of the material that has been removed or to which
            access has been disabled and the location at which the material
            appeared before it was removed or access to it was disabled;
          </li>
          <li>
            A statement under penalty of perjury that the subscriber has a good
            faith belief that the material was removed or disabled as a result
            of mistake or misidentification of the material to be removed or
            disabled; and
          </li>
          <li>
            The subscriber's name, address, and telephone number, and a
            statement that the subscriber consents to the jurisdiction of
            Federal District Court for the judicial district in which the
            address is located, or if the subscriber's address is outside of the
            United States, for any judicial district in which Company may be
            found, and that the subscriber will accept service of process from
            the person who provided notification above or an agent of such
            person.
          </li>
        </ul>
        <h4>Legal Disputes </h4>
        <p>
          PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL
          HAVE A SUBSTANTIAL IMPACT ON HOW CLAIMS YOU AND SHE TRACKS HAVE
          AGAINST EACH OTHER ARE RESOLVED.
        </p>
        <p>
          You and She Tracks agree that any claim or dispute at law or equity
          that has arisen or may arise between us relating in any way to or
          arising out of this or previous versions of our these Terms, your use
          of or access to the Services, or any products or services sold or
          purchased through the Services, will be resolved in accordance with
          the provisions set forth in this Legal Disputes Section.
        </p>
        <p>
          Choice of Law. These Terms are made under and will be governed by and
          construed in accordance with the laws of the State of Missouri,
          without giving effect to any principles that provide for the
          application of the law of another jurisdiction.
        </p>
        <p>
          Claim Limitations. You agree that any cause of action arising out of
          or related to the Services must commence within one (1) year after the
          cause of action accrues. Otherwise, such cause of action is
          permanently barred.
        </p>
        <h4>Agreement to Arbitrate</h4>
        <p>
          You and She Tracks each agree that any and all disputes or claims that
          have arisen or may arise between you and She Tracks relating in any
          way to or arising out of this or previous versions of these Terms,
          your use of or access to She Tracks 's Services, or any products or
          services sold, offered, or purchased through our Services will be
          resolved exclusively through final and binding arbitration, rather
          than in court. Alternatively, you may assert your claims in small
          claims court in Joplin, Missouri, if your claims qualify and so long
          as the matter remains in such court and advances only on an individual
          (non-class, non-representative) basis. The Federal Arbitration Act
          governs the interpretation and enforcement of this agreement to
          Arbitrate.
        </p>
        <p>
          The arbitration will be conducted by JAMS Arbitration ("JAMS") under
          its applicable rules and procedures, as modified by this agreement to
          Arbitrate. The arbitration will be conducted before one commercial
          arbitrator with substantial experience in resolving commercial
          contract disputes.
        </p>
        <p>
          Your rights will be determined by a neutral arbitrator and not a judge
          or jury. You understand that arbitration procedures can be more
          limited that rules applicable in court. Arbitrator decisions are as
          enforceable as any court order and are subject to very limited review
          in court.{" "}
        </p>
        <p>
          You and we must abide by the following rules: (a) ANY CLAIMS BROUGHT
          BY YOU OR US MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND
          NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE PROCEEDING; (b) THE ARBITRATOR MAY NOT CONSOLIDATE MORE
          THAN ONE PERSON’S CLAIMS, MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
          REPRESENTATIVE OR CLASS PROCEEDING, AND MAY NOT AWARD CLASS-WIDE
          RELIEF, (c) the arbitrator will honor claims of privilege and privacy
          recognized at law; (d) the arbitration will be confidential, and
          neither you nor we may disclose the existence, content or results of
          any arbitration, except as may be required by law or for purposes of
          enforcement of the arbitration award; (e) the arbitrator may award any
          individual relief or individual remedies that are permitted by
          applicable law; and (f) each side pays its own attorneys’ fees and
          expenses unless there is a statutory provision that requires the
          prevailing party to be paid its fees and litigation expenses, and then
          in such instance, the fees and costs awarded will be determined by the
          applicable law.
        </p>
        <p>
          With the exception of subparts (a) and (b) in the paragraph above
          (prohibiting arbitration on a class or collective basis), if any part
          of this arbitration provision is deemed to be invalid, unenforceable
          or illegal, or otherwise conflicts with the Rules and Procedures, then
          the balance of this arbitration provision will remain in effect and
          will be construed in accordance with its terms as if the invalid,
          unenforceable, illegal or conflicting provision were not contained
          herein. If, however, either subpart (a) or (b) is found to be invalid,
          unenforceable or illegal, then the entirety of this arbitration
          provision will be null and void, and neither you nor we will be
          entitled to arbitration. If for any reason a claim proceeds in court
          rather than in arbitration, the dispute will be exclusively brought in
          state or federal court in Joplin, Missouri.
        </p>
        <h4>General</h4>
        <p>
          Changes to Terms. These Terms are subject to occasional revision, and
          if we make any substantial changes, we may notify you by prominently
          posting notice of the changes on our Platform. Any significant changes
          to these Terms will be effective 30 days after posting such notice.
          Continued use of our Platform or Services following notice of such
          changes will indicate your acknowledgement of such changes and
          agreement to be bound by the terms and conditions of such changes.
        </p>
        <p>
          Copyright/Trademark Information. Copyright © 2022 She Tracks L.L.C.
          All rights reserved. All trademarks, logos and service marks (“Marks”)
          displayed on the Platform are our property or the property of other
          third parties. You are not permitted to use these Marks without our
          prior written consent or the consent of such third party which may own
          the Marks.
        </p>
        <p className="m-0">Contact Information:</p>
        <p className="m-0">She Tracks </p>
        <p className="m-0">Email: shetracksapp@gmail.com </p>
        <p className="m-0">Last Updated October 17, 2024</p>
      </div>
    </div>
  );
}
