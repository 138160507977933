import React from "react";
import { Container, Table } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <div class="container">
        <div class="privacy_text">
          <h1>Privacy Policy</h1>
          <p>
            She Tracks (“She Tracks”, “us”, “our”, and “we”) is committed to
            maintaining robust privacy protections for its users. Our Privacy
            Policy (“Privacy Policy”) is designed to help you understand how we
            collect, use and safeguard the information you provide to us and to
            assist you in making informed decisions when using our Service.
          </p>

          <p>
            <b>
              By accepting our Privacy Policy and Terms of Use (found here:
              located at companyurl/terms), you consent to our collection,
              storage, use and disclosure of your personal information as
              described in this Privacy Policy. Capitalized terms in this policy
              have the same meaning given to them in our Terms of Use, unless
              otherwise defined in this policy.{" "}
            </b>
          </p>

          <h4>Children’s Online Privacy Protection Act</h4>
          <p>
            Minors under eighteen (18) years of age are prohibited from using
            the Platform and Services. The Platform and Services are not
            intended for children under the age of 18. If you are under 18 years
            old, please do not provide personally identifiable information of
            any kind whatsoever on the Platform or Services. We do not
            intentionally gather Personal Information from visitors who are
            under the age of 13. In the event that we learn that we have
            collected personal information from a child under age 13 without
            parental consent, we will delete that information as quickly as
            possible. If you believe that we might have any information from or
            about a child under 13, please contact us.
          </p>

          <h4>Types of Data We Collect</h4>
          <p>
            We collect “Non-Personal Information” and “Personal Information.”
            Non-Personal Information includes information that cannot be used to
            personally identify you, such as anonymous usage data, general
            demographic information we may collect, referring/exit pages and
            URLs, platform types, preferences you submit and preferences that
            are generated based on the data you submit and number of clicks.
            “Personal Information” means data that allows someone to identify or
            contact you, including, for example, your name, address, telephone
            number, e-mail address, as well as any other non-public information
            about you that is associated with or linked to any of the foregoing
            data.
          </p>

          <h4>Information You Provide to Us</h4>

          <p>
            We may collect Personal Information from you, such as your first and
            last name, e-mail and mailing address, phone number and password
            when you complete a contact form on our Platform, or create or
            log-in to your Account.
          </p>

          <p>
            You may voluntarily provide health-related information such as
            menstrual cycle details, weight, prescription information, food
            tracking data, activity tracking information, mood details, and any
            additional comments you choose to store on the Platform.
          </p>

          <p>
            When you subscribe to the Services, our payment processor will
            collect all information necessary to complete the transaction,
            including your name, credit card information, billing information.
          </p>

          <p>
            We also retain information on your behalf, such as files, messages,
            menstrual cycle data, weight tracking, prescription information,
            food logs, activity tracking data, mood entries, and any additional
            notes or comments that you store using your Account.
          </p>
          <p>
            If you provide us feedback or contact us via e-mail, we will collect
            your name and e-mail address, as well as any other content included
            in the e-mail, in order to send you a reply.
          </p>

          <p>
            When you participate in one of our surveys, we may collect
            additional information that you knowingly provide.
          </p>

          <p>
            When you post messages on the Services or on a Social Media Platform
            where we have a page or presence the information contained in your
            posting may be republished on our Platform and other users may be
            able to see them.
          </p>

          <h4>Information We Collect from Third-Party Devices</h4>
          <p>
            She Tracks integrates with third-party devices, such as Apple Watch,
            Fitbit, and Oura Ring, to import data related to your physical
            activity, calories burned, and other health metrics. By connecting
            these devices, you consent to She Tracks collecting, processing, and
            utilizing data from these devices to provide insights and features
            on the Platform. Please be aware that the data originates from
            third-party devices, and She Tracks does not guarantee its accuracy,
            completeness, or suitability for medical or health-related
            decisions. Data collection and usage by these third-party providers
            are governed by their respective privacy policies and terms. We
            recommend that you review these policies to understand how your
            information is handled by the third-party provider.
          </p>

          <h4>Information Collected via Technology</h4>
          <p>
            In an effort to improve the quality of the Service, we track
            information provided to us by your browser or by our software
            application when you view or use the Service, such as the website
            you came from (known as the “referring URL”), the type of browser
            you use, the device from which you connected to the Service, the
            time and date of access, and other information that does not
            personally identify you. We track this information using cookies, or
            small text files which include an anonymous unique identifier.
            Cookies are sent to a user’s browser from our servers and are stored
            on the user’s computer hard drive. Sending a cookie to a user’s
            browser enables us to collect Non-Personal information about that
            user and keep a record of the user’s preferences when utilizing our
            services, both on an individual and aggregate basis. We may also ask
            advertisers or other partners to serve ads to your devices, which
            may use cookies or similar technology.
          </p>

          <p>
            We may also use third-party analytics services such as Google
            Analytics or Google Adsense to collect information about how you use
            and interact with our Services. Such third-party analytics services
            may use cookies to gather information such as the pages you visited,
            your IP address, a date/time stamp for your visit and which site
            referred you to the Platform. This helps us provide a better user
            experience and improve usability.
          </p>
          <p>
            We may utilize pixels provided by various third-party web analytics
            and marketing services (such as Facebook, Google, and others) to
            help us understand and improve our advertising efforts. These pixels
            enable us to track conversions from ads, optimize ad performance
            based on collected data, build targeted audiences for future ads,
            and remarket to people who have previously interacted with our
            Platform. When you visit our Platform, these pixels may collect
            information about your interaction with our Platform, such as the
            pages you view, products or services you explore, and whether you
            complete any actions, like making a purchase. This data is collected
            through the use of cookies and similar technologies. The collected
            information may be shared with the respective third-party service
            providers and used in accordance with their data use policies. These
            third-party providers use the data to offer insights into the
            effectiveness of our ads and to deliver targeted advertising based
            on your online activity. We do not share any of your personally
            identifiable information directly with these third parties through
            the pixels. You have the right to opt out of the collection and use
            of your information for targeted advertising. For information on
            managing and deleting cookies, please refer to your browser’s help
            documentation. Additionally, many advertising services allow you to
            control ad preferences and opt-out options within their account
            settings.
          </p>

          <h4>Use of Your Personal Information</h4>

          <p>
            Depending on the features of the Platform you use, we may process
            your personal data based on one or more of the following legal
            bases:
          </p>
          <p>
            Your Consent: You may give us permission to process specific health
            data to enhance your experience on the Platform. Example: Collecting
            and processing menstrual cycle data to provide insights and
            predictions for your cycle.
          </p>
          <p>
            Contractual Obligations: We may process personal data to fulfill our
            contractual obligations to you, such as managing your She Tracks
            account and delivering the requested services.
          </p>
          <p>
            Legitimate Interests: We may process personal data to improve our
            services, manage the Platform more effectively, and secure our
            services, as long as these interests do not override your rights.
            Examples: Conducting vulnerability assessments to protect your data
            or analyzing usage data to identify and fix bugs.
          </p>
          <p>
            Legal Obligations: We may be required to process certain personal
            data to comply with applicable laws and regulations.
          </p>
          <p>
            Below is a table that describes some of the purposes for which we
            process your personal data, the legal basis for processing, and
            examples where applicable:
          </p>

          <div>
            <Table size="sm" className="table-cmn">
              <thead>
                <tr>
                  <th>Purpose of Processing</th>
                  <th>Legal Basis for Processing</th>
                  <th>Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <b>Account Management and Security </b>
                    </div>
                  </td>
                  <td>Contractual necessity</td>
                  <td>
                    To create and secure your account on our Platform, verify
                    your identity, and manage user access.
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td>
                    <b>Service Provision and Personalization</b>
                  </td>
                  <td>Contractual necessity and consent</td>
                  <td>
                    Integrate and display data from third-party devices (such as
                    Apple Watch, Fitbit, and Oura Ring) to enhance your
                    experience.
                  </td>
                </tr>

                <tr>
                  {" "}
                  <td>
                    <b>Personalized Content and Feature Delivery</b>
                  </td>
                  <td>Legitimate interest</td>
                  <td>
                    To tailor content, features, and services based on your
                    interactions and preferences on the Platform.
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td>
                    <b>Administrative Communication</b>
                  </td>
                  <td>Contractual necessity</td>
                  <td>
                    Send you notifications about your account, such as
                    verification emails, security alerts, and maintenance
                    updates.
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>Marketing and Promotional Activities</b>
                  </td>
                  <td>Consent</td>
                  <td>
                    Send newsletters, offers, and promotions related to our
                    Services or on behalf of third-party partners.
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td>
                    <b>Customer Support and User Feedback</b>
                  </td>
                  <td>Legitimate interest</td>
                  <td>
                    Respond to your inquiries, provide customer support, and
                    address any questions or feedback you may have.
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td>
                    <b>Data Analysis and Platform Improvements</b>
                  </td>
                  <td>Legitimate interest</td>
                  <td>
                    Conduct data analysis to understand user engagement, improve
                    the Platform, and ensure a quality user experience.
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td>
                    <b>Advertising and Third-Party Insights</b>
                  </td>
                  <td>Legitimate interest and consent</td>
                  <td>
                    Provide relevant advertisements on the Platform by sharing
                    insights with third-party advertisers to tailor ads to your
                    interests.
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <h4>Use of Non-Personal Information</h4>

          <p>
            In general, we use Non-Personal Information to help us improve the
            Service and customize the user experience. This may include
            aggregated data on device type, browser type, general location, and
            usage patterns. We aggregate Non-Personal Information in order to
            track trends, analyze usage patterns on the Platform, improve our
            content and offerings, and tailor advertisements. This Privacy
            Policy does not limit in any way our use or disclosure of
            Non-Personal Information. We reserve the right to use and disclose
            such Non-Personal Information to our partners, advertisers, and
            other third parties at our discretion, as this information does not
            identify individual users. If our information practices change at
            any time in the future, we will post the policy changes on the
            Platform so that you may opt out of any new information practices.
            We suggest that you check the Platform periodically if you are
            concerned about how your information is used.
          </p>

          <h4>Important Information About Platform Permissions </h4>

          <p>
            Most mobile platforms (iOS, Android, etc.) have defined certain
            types of device data that apps cannot access without your consent.
            And these platforms have different permission systems for obtaining
            your consent. The iOS platform will alert you the first time our App
            wants permission to access certain types of data and will let you
            consent (or not consent) to that request. Android devices will
            notify you of the permissions that our App seeks before you first
            use the app, and your use of the app constitutes your consent.
          </p>

          <h4>Disclosure of Your Personal Information</h4>
          <p>
            Our company does not sell your personal information. However, to
            provide you with the Services or for other business purposes, we may
            share your personal information with third parties as described
            below:
          </p>
          <p>
            Third-Party Service Providers. We may share your Personal
            Information with third-party service providers to facilitate the
            Services we offer you through our Platform. Specifically, these
            service providers assist us in:
          </p>

          <p>
            Subscription Management: We use Apple and Google Play for handling
            subscriptions and in-app purchases. When you subscribe to our
            Services through these systems, your information is processed by
            Apple or Google in accordance with their respective privacy
            policies. Our payment processors collect and handle information
            necessary to complete transactions, including payment and billing
            details.
          </p>
          <p>
            Account Verification: For email verification and one-time passwords
            (OTP), we use Twilio SendGrid to securely verify your account.
          </p>
          <p>
            Advertising: Our free version utilizes Google AdMob for delivering
            advertisements. Google AdMob may collect information about your
            device and interactions with ads, as governed by Google’s privacy
            policy.
          </p>
          <p>
            Hosting and Customer Support: Service providers help host our
            Platform, manage our servers, and provide customer support to
            address your inquiries.
          </p>
          <p>
            Quality Assurance and Performance Monitoring: We conduct quality
            assurance testing and monitor the Platform’s performance to ensure
            it operates as expe
          </p>
          <p>
            Marketing and Data Analysis: Third parties may assist in marketing
            efforts, conducting data analysis to understand user engagement, and
            improving the Platform.
          </p>
          <p>
            Authentication and Account Creation: We enable account creation and
            authentication through sign-on via Google and Apple accounts,
            ensuring a simple and secure access experience.
          </p>
          <p>
            Technical Support and Troubleshooting: Our service providers offer
            technical support and assist in troubleshooting issues on the
            Platform.
          </p>
          <p>
            Additional Services: We work with third parties to provide other
            services aimed at enhancing the user experience on our Platform.
          </p>

          <p>
            These service providers are contractually obligated to protect the
            confidentiality and security of your Personal Information and to use
            it only as directed by us.
          </p>

          <p>
            Affiliates and Business Partners. We may share your Personal
            Information with our affiliates and business partners to perform
            functions and provide services on our behalf, such as marketing
            initiatives or improving the Platform’s functionality. These
            partners are required to use your Personal Information only for the
            purposes outlined by us and in compliance with applicable laws.
          </p>

          <p>
            Corporate Transactions. In the event of a merger, acquisition,
            reorganization, sale of assets, or bankruptcy, your Personal
            Information may be transferred to a successor entity as part of the
            transaction. We will take reasonable steps to ensure that your
            Personal Information remains secure and is handled in accordance
            with this Privacy Policy.
          </p>
          <p>
            Other Disclosures. She Tracks is committed to protecting your
            privacy and the confidentiality of your personal health information.
            We will not disclose your Personal Information to any third parties
            except in the following limited circumstances, and only to the
            extent required or permitted by law:
          </p>

          <p>
            Legal Obligations: We may disclose Personal Information if required
            to comply with applicable laws, regulations, or legal processes
            (such as subpoenas, court orders, or other legal requests). In such
            cases, we will take reasonable measures to limit the scope of the
            information disclosed and, where possible, will notify you in
            advance.
          </p>

          <p>
            Protection and Safety: We may disclose Personal Information to
            protect the rights, property, or safety of She Tracks, our users, or
            others. This includes actions to detect, prevent, or otherwise
            address fraud, security, or technical issues, and to enforce our
            Terms of Use.
          </p>
          <p>
            Responding to Government Requests: In the rare event we receive a
            request from a public authority, including for law enforcement or
            national security purposes, we will carefully evaluate the legality
            and necessity of the request. Where permitted, She Tracks will
            strive to notify you and to seek the minimum disclosure possible in
            response to such requests. Your personal health information is
            treated with the utmost sensitivity, and we will take all possible
            steps to protect it from unwarranted disclosure.
          </p>

          <p>
            Commitment to Transparency. She Tracks believes in transparency
            regarding our handling of personal health information. Should we
            receive requests from public authorities or other third parties for
            user data, we will evaluate each request carefully. We are committed
            to protecting your privacy and will take appropriate measures to
            ensure that any disclosures are in line with our privacy principles
            and, where possible, will notify you of such disclosures.
          </p>
          <p>
            Data Security During Disclosures. When disclosing your Personal
            Information to third parties as described above, we take steps to
            protect the confidentiality and security of your data. We strive to
            use industry-standard safeguards and require third parties to
            implement similar protections, ensuring your information remains
            secure throughout any processing.
          </p>
          <h4>Links to Third Party Websites</h4>
          <p>
            As part of the Service, we may provide links to or compatibility
            with other websites or applications. However, we are not responsible
            for the privacy practices employed by those websites or the
            information or content they contain. This Privacy Policy applies
            solely to information collected by us through the Platform and the
            Service. Therefore, this Privacy Policy does not apply to your use
            of a third party website accessed by selecting a link on our
            Platform or via our Service. To the extent that you access or use
            the Service through or on another website or application, then the
            privacy policy of that other website or application will apply to
            your access or use of that site or application. We encourage our
            users to read the privacy statements of other websites before
            proceeding to use them.
          </p>
          <h4>Your Rights Regarding the Use of Your Personal Information</h4>
          <p>
            You have the right at any time to prevent us from contacting you for
            marketing purposes. When we send a promotional communication to a
            user, the user can opt out of further promotional communications by
            following the unsubscribe instructions provided in each promotional
            e-mail. You can also indicate that you do not wish to receive
            marketing communications from us in the “Settings” section of your
            Account. Please note that notwithstanding the promotional
            preferences you indicate by either unsubscribing or opting out in
            the Settings section of your Account, we may continue to send you
            administrative emails including, for example, periodic updates to
            our Privacy Policy.
          </p>
          <h4>Access, Correction, Deletion</h4>
          <p>
            We respect your privacy rights and provide you with reasonable
            access to the Personal Information that you may have provided
            through your use of the Services. If you wish to access or amend any
            other Personal Information we hold about you, or to request that we
            delete any information about you that we have obtained from our
            Service, you may contact us at: shetracksapp@gmail.com with the
            email subject as “Personal Information Query.”
          </p>
          <p>
            At your request, we will have any reference to you deleted or
            blocked in our database.
          </p>
          <p>
            You may update, correct, or delete your Account information and
            preferences at any time by accessing your Account settings page on
            the Service. Please note that while any changes you make will be
            reflected in active user databases instantly or within a reasonable
            period of time, we may retain all information you submit for
            backups, archiving, prevention of fraud and abuse, analytics,
            satisfaction of legal obligations, or where we otherwise reasonably
            believe that we have a legitimate reason to do so.
          </p>
          <p>
            You may decline to share certain Personal Information with us, in
            which case we may not be able to provide to you some of the features
            and functionality of the Service.
          </p>
          <p>
            At any time, you may object to the processing of your Personal
            Information, on legitimate grounds, except if otherwise permitted by
            applicable law.
          </p>
          <h4>Security of Your Personal Information</h4>
          <p>
            We implement security measures designed to protect your information
            from unauthorized access. Your account is protected by your account
            password and we urge you to take steps to keep your Personal
            Information safe by not disclosing your password and by logging out
            of your account after each use. We further protect your information
            from potential security breaches by implementing certain
            technological security measures However, these measures do not
            guarantee that your information will not be accessed, disclosed,
            altered or destroyed by breach of such firewalls and secure server
            software. While we use reasonable efforts to protect your Personal
            Information, we cannot guarantee its absolute security. By using our
            Service, you acknowledge that you understand and agree to assume
            these risks.
          </p>
          <h4>How Long We Retain Your Data</h4>
          <p>
            We will keep hold of your data for no longer than necessary. The
            length of time we retain it will depend on any legal obligations we
            have (such as tax recording purposes), the nature of any contracts
            we have in place with you, the existence of your consent or our
            legitimate interests as a business.
          </p>
          <h4>Do Not Track Signals</h4>
          <p>
            Certain state laws require us to indicate whether we honor “Do Not
            Track” settings in your browser. She Tracks adheres to the standards
            set out in this Privacy Policy and does not monitor or follow any Do
            Not Track browser requests.{" "}
          </p>
          <h4>Notices</h4>
          <p>
            Notice to California Residents. The California Consumer Privacy Act
            (“CCPA”) provides California consumers with specific rights
            regarding their Personal Information. You have the right to request
            that businesses subject to the CCPA: (1) disclose certain
            information to you about their collection and use of your Personal
            Information over the past 12 months; (2) delete Personal Information
            collected from you, subject to certain exceptions; and (3) ask
            whether the business sells Personal Information and right to opt-out
            of that sale. To request the above information, you may contact us
            at: shetracksapp@gmail.com with the email subject as California
            Disclosure Information.{" "}
          </p>
          <p>
            California law requires that we provide you with a summary of your
            privacy rights under the California Online Privacy Protection Act
            (“California Act”) and the California Business and Professions Code.
            As required by the California Act, this Privacy Policy identifies
            the categories of personally identifiable information that we
            collect through our Platform about individual consumers who use or
            visit our Platform and the categories of third-party persons or
            entities with whom such personally identifiable information may be
            shared.
          </p>
          <p>
            Notices to International Users. The Platform is hosted in the United
            States. If you are a User accessing the the Platform or Services (or
            providing information that is hosted on or routed through the
            Platform) from the European Union (“EU”), Asia, or any other region
            with laws or regulations governing personal data collection, use,
            and disclosure, that differ from United States laws, please note
            that you are transferring your personal data to the United States
            which does not have the same data protection laws as the EU and
            other regions, and by providing your personal data you permit the
            use of your personal data for the uses identified above in
            accordance with the Privacy Policy.
          </p>
          <h4>Changes to This Privacy Policy</h4>
          <p>
            We reserve the right to change this policy and our Terms of Service
            at any time. We will notify you of significant changes to our
            Privacy Policy by sending a notice to the primary email address
            specified in your account or by placing a prominent notice on our
            site. Significant changes will go into effect 30 days following such
            notification. Non-material changes or clarifications will take
            effect immediately. You should periodically check the Platform and
            this privacy page for updates.
          </p>
          <h4>Contact Information</h4>
          <p>
            If you have any questions regarding this Privacy Policy or the
            practices of the Platform please contact us by sending an email to
            shetracksapp@gmail.com
          </p>
          <h4>Contact Information</h4>
          <p>This Privacy Policy was last updated on October 17, 2024.</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
