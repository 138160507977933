import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DelecteAccount,
  changeStatus,
  getUserProfile,
} from "../Redux/Actions/adminAction";
import logo from "../Assets/Images/placeholder 1 .png";
import { toast } from "react-toastify";

export default function CustomerProfile() {
  const [show, setshow] = useState(false);
  const [showdel, setshowdel] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usermgmtData.userdetails);
  console.log(data, "userList");
  let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUserProfile({ userId: id }));
  }, [id]);

  const handledelclick = async () => {
    setshowdel(true);
  };

  const handleclick = async () => {
    setshow(true);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              {/* <div className="cmn-btn">
                <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.001 13.541C9.58698 13.541 9.25098 13.205 9.25098 12.791V0.75C9.25098 0.336 9.58698 0 10.001 0C10.415 0 10.751 0.336 10.751 0.75V12.791C10.751 13.205 10.415 13.541 10.001 13.541Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.0008 13.541C9.80183 13.541 9.60983 13.462 9.46983 13.32L6.55383 10.393C6.26183 10.099 6.26283 9.62403 6.55583 9.33203C6.84983 9.04003 7.32383 9.04003 7.61583 9.33403L10.0008 11.729L12.3858 9.33403C12.6778 9.04003 13.1518 9.04003 13.4458 9.33203C13.7388 9.62403 13.7398 10.099 13.4478 10.393L10.5318 13.32C10.3918 13.462 10.1998 13.541 10.0008 13.541Z"
                      fill="white"
                    />
                    <mask
                      id="mask0_1581_17087"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="4"
                      width="20"
                      height="15"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 4.73242H19.9998V18.4764H0V4.73242Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1581_17087)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.575 18.4764H4.435C1.99 18.4764 0 16.4874 0 14.0414V9.15642C0 6.71642 1.985 4.73242 4.426 4.73242H5.367C5.781 4.73242 6.117 5.06842 6.117 5.48242C6.117 5.89642 5.781 6.23242 5.367 6.23242H4.426C2.812 6.23242 1.5 7.54342 1.5 9.15642V14.0414C1.5 15.6604 2.816 16.9764 4.435 16.9764H15.575C17.187 16.9764 18.5 15.6634 18.5 14.0514V9.16742C18.5 7.54842 17.183 6.23242 15.566 6.23242H14.634C14.22 6.23242 13.884 5.89642 13.884 5.48242C13.884 5.06842 14.22 4.73242 14.634 4.73242H15.566C18.011 4.73242 20 6.72242 20 9.16742V14.0514C20 16.4914 18.014 18.4764 15.575 18.4764Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Export
                </Link>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                {/* <div className="user-profile-main">
                  <Row>
                    <Col lg={12}>
                      <Figure className="d-flex">
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src={
                            data?.data?.profile_image
                              ? `${BASE_URL}${data?.data?.profile_image}`
                              : logo
                          }
                        />
                        <Figure.Caption>
                          <h2>{data?.data?.name || "N/A"}</h2>
                          <p>{data?.data?.email || "N/A"}</p>
                      
                        </Figure.Caption>
                      </Figure>
                    </Col>
                  </Row>
                </div> */}
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3  py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Profile info</h2>
                        <div className="default-links">
                          <ul>
                            <li>
                              <h6>Profile Name</h6>
                              <p>{data?.data?.name || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Email</h6>
                              <p>{data?.data?.email || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Age</h6>
                              <p>{data?.data?.age || "N/A"}</p>
                            </li>
                            <li>
                              <h6>Subscription</h6>
                              <p>N/A</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          <ul>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                          
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handledelclick}
                          >
                            Delete Account
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={handleclick}
                          >
                            Suspend Account
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Modal show={show} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(changeStatus({ id, status: { status: 2 } })).then(
                (res) => {
                  console.log(res,"rerererer")
                  if (res?.payload?.status == 200) {
                    toast.success(res?.payload?.message);
                    setshow(false);
                    navigate("/UserManagement");
                  } else {
                    toast.error("Something went wrong");
                  }
                }
              );
            }}
          >
            Yes
          </Button>
          <Button  className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showdel} className="delete-popup">
        <Modal.Body>
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn"
            onClick={() => {
              dispatch(DelecteAccount(id)).then((res) => {
                console.log(res, "res");
                if (res?.payload?.data?.status == 200) {
                  toast.success(res?.payload?.data?.message);
                  setshowdel(false);
                  navigate("/UserManagement");
                } else {
                  toast.error("Something went wrong");
                }
              });
            }}
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowdel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
